<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.patientServiceReport") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <!-- <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
        "
      >
        <crm-column-settings
          :columns="columns"
          :modelName="'patientServiceReport'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div> -->
    </div>

    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service_name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.settings"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>

          <th v-if="columns.service_name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.service_name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="partnerDoctorReport in list" :key="partnerDoctorReport.id">
          <td v-if="columns.id.show">{{ partnerDoctorReport.id }}</td>
          <td v-if="columns.service_name.show">{{ partnerDoctorReport.name }}</td>
          <td v-if="columns.settings.show">
            <el-button
              @click="showReport(partnerDoctorReport)"
              type="success"
              icon="el-icon-view"
              round
              >{{ $t("message.reports") }}</el-button
            >
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      class="popups_title"
      size="80%"
      :visible.sync="drawerShow"
      @opened="drawerOpened('report-service-patients')"
      :with-header="false"
    >
      <ReportService
        :selected="selectedModel"
        @c-close="drawerShow = false"
        ref="report-service-patients"
      >
      </ReportService>
    </el-drawer>
    <el-drawer
      class="popups_title"
      size="80%"
      :visible.sync="drawerParentShow"
      @opened="drawerOpened('report-parent-service-patients')"
      :with-header="false"
    >
      <ReportParentService
        :selected="selectedParentModel"
        @c-close="drawerParentShow = false"
        ref="report-parent-service-patients"
      >
      </ReportParentService>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReportService from "./components/report-service-patients";
import ReportParentService from "./components/report-parent-service-patients";
import lists from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [lists, drawer],
  components: {
    ReportService,
    ReportParentService,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      filterForm: {},
      drawerShow: false,
      drawerParentShow: false,
      reopenShow: false,
      reopenUpdate: false,
      excel_data: [],
      // drawer: false,
      excel_fields: {},
      selectedModel: {},
      selectedParentModel: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "patientServiceReport/list",
      columns: "patientServiceReport/columns",
      pagination: "patientServiceReport/pagination",
      filter: "patientServiceReport/filter",
      sort: "patientServiceReport/sort",
    }),
  },
  mounted() {
    this.index();
  },
  methods: {
    ...mapActions({
      // updateList: "patientServiceReport/patientList",
      index: "patientServiceReport/index",
      updateSort: "patientServiceReport/updateSort",
      updateFilter: "patientServiceReport/updateFilter",
      updateColumn: "patientServiceReport/updateColumn",
      updatePagination: "patientServiceReport/updatePagination",
      empty: "patientServiceReport/empty",
      refreshData: "patientServiceReport/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      this.index(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    showReport(model) {
      if (model.code == "102") {
        this.drawerParentShow = true;
        this.selectedParentModel = model;
      } else {
        this.drawerShow = true;
        this.selectedModel = model;
      }
    },
    async show(model) {
      this.selectedModel = model;
      this.drawer.show.status = true;
      this.$loadingCursor("wait");
      await this.showModel(model)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    drawerOpened(ref) {
      this.$refs[ref].afterOpen();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      // this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #000;
      font-weight: 200 !important;
      font-size: 12px !important;
    }
  }
}
</style>



